<template>
<div class="main gray-bg">
  <van-nav-bar class="top-bar" title="订单记录" left-arrow @click-left="onClickLeft"/>
  <van-notice-bar color="#CC8B00" background="#FFF6E5" left-icon="warning" class="top-notice">点击订单号即可复制</van-notice-bar>
  <van-list class="card-pane" v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getOrderList">
    <div class="card-cell" v-for="item in list" :key="item.id">
      <div class="card-head">
        <div class="card-head-title">
          <div class="van-ellipsis title">订单号：{{ item.pay_order_number}}</div>
          <van-tag color="#845EC2" @click="asyncCopy(item.pay_order_number)" plain round>复制</van-tag>
        </div>
        <div class="card-head-value">支付成功</div>
      </div>
      <div class="card-body">
        <div class="van-ellipsis">商品：{{ item.props_name}}</div>
        <div class="van-ellipsis">下单时间：{{date(item.pay_time, 'Y-m-d H:i:s')}}</div>
      </div>
      <div class="card-bottom">金额: <span class="fs12">￥</span><span class="fs16">{{ item.cost }}</span></div>
    </div>
  </van-list>
</div>
</template>

<script>
import {NavBar, NoticeBar, Tag, List,PullRefresh} from "vant";
import "@/style/common.css"
import {ref,reactive} from "vue";
import {orderList} from "@/api/spend"
import {asyncCopy, date} from "@/utils/function"
import {exitPage} from "@/utils/callback";
export default {
  name: "SpendList",
  components:{
    [NavBar.name]:NavBar,
    [NoticeBar.name]:NoticeBar,
    [Tag.name]:Tag,
    [List.name]:List,
    [PullRefresh.name]:PullRefresh,
  },
  setup(){
    //初始化
    const list = ref([]);
    const query = reactive({
      last:0,
      limit:10
    })
    const loading = ref(false);
    const finished = ref(false);
    const getOrderList = ()=>{
      loading.value = true;
      let oldList = list.value;
      orderList(query).then(({data})=>{
        loading.value = false;
        list.value = [...oldList, ...data.list]
        query.last = data.last
        if(data.list.length < query.limit || data.last == 0){
          finished.value = true;
        }
      })
    }
    //点击返回
    const onClickLeft = ()=>{
      exitPage()
    };
    return {
      list,
      loading,
      finished,
      asyncCopy,
      date,
      getOrderList,
      onClickLeft
    }
  }
}
</script>

<style scoped>
.card-cell{
  background: #FFFFFF;
  margin-bottom:12px;
  padding:0px 16px;
}
.card-head{
  height:50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-head-title{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  max-width:calc(100% - 80px)
}

.card-head-title .van-tag{
  display: inline-block;
  width:46px;
  text-align: center;
  height: 18px;
  line-height: 18px;
  border-radius: 20px;
  border-color: #845EC2;
  font-weight: 500;
  color: #845EC2;
  font-size: 12px;
  box-sizing: border-box;
}
.card-head-value{
  width:80px;
  font-size: 15px;
  font-weight: 600;
  color: #86909C;
}
.card-body{
  background: #F7F8FA;
  border-radius: 3px;
  padding:6px 12px;
  font-size: 13px;
  font-weight: 400;
  color: #747C89;
  line-height:24px;
  text-align: left;
}
.card-bottom{
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  height:46px;
  line-height:46px;
}
.card-bottom span{
  color: #FF0032;
}
</style>
